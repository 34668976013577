import { AxiosResponse } from "axios";
import { ICompany, ServerFormError } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";

export const useListCompanyKey = "companies";

function useListCompany() {
  const { axiosInstance, API_ROUTES } = useGlobalAppState();
  return useQuery<AxiosResponse<ICompany[]>, ServerFormError>({
    queryKey: [useListCompanyKey],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES.company.list);
    },
  });
}

export default useListCompany;
