import InactiveAccountImage from "@/shared/images/inactive-account.svg";
import ucallLogoDark from "@/shared/images/ucall-logo-dark.png";
import Image from "next/image";
import { theme } from "antd";
import Link from "next/link";
import { useAuthentication } from "../AuthenticationProvider";

const InactiveAccountScreen = () => {
  const { logout } = useAuthentication();
  const {
    token: { colorPrimary },
  } = theme.useToken() as any;

  return (
    <>
      <div className="w-100 h-100">
        <div
          className="w-100 py-4 px-5 mb-4"
          style={{
            backgroundColor: colorPrimary,
          }}>
          {" "}
          <Link href="/">
            <Image
              alt="ucall-logo-dark"
              src={ucallLogoDark}
              height={59}
              style={{ objectFit: "cover" }}
            />
          </Link>
        </div>
        <div className="w-100 pt-5 d-flex justify-content-center">
          <div className="text-center">
            <Image
              alt=""
              height={410}
              src={InactiveAccountImage}
              className="mb-3"
            />
            <p
              className="text-center mt-4 h3"
              style={{
                color: colorPrimary,
              }}>
              UCALL đã gửi link xác thực tài khoản đến email của bạn
              <br />
              Vui lòng kiểm tra để xác thực!
            </p>

            <p
              className="text-center mt-5"
              onClick={() => {
                logout();
              }}
              style={{
                color: colorPrimary,
                cursor: "pointer",
              }}>
              Hoặc đăng nhập bằng tài khoản khác
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveAccountScreen;
