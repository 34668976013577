"use client";
import useMyProfile from "@/services/profile/useMyProfile";
import useListCompany from "@/services/company/useListCompany";
import { Button, Card, Empty, notification } from "antd";
import { BankOutlined } from "@ant-design/icons";
import Link from "next/link";
import { AppCompanyRoutes, AppRoutes } from "@/config/app/routes";
import { useTranslations } from "next-intl";
import FadeIn from "@/components/shared/FadeIn";
import { withAuthenticationGuarded } from "@/providers/app/Authentication/AuthenticationGuarded";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
const DashboardPage = () => {
  const t = useTranslations("boarding");
  const { replace } = useRouter();
  const { data, isLoading } = useMyProfile();
  const { data: listCompaniesData } = useListCompany();

  // const dataCompany = data?.data?.companies?.[0];

  // useEffect(() => {
  //   if (
  //     dataCompany?.init_information &&
  //     Object.keys(dataCompany?.init_information).length === 0 &&
  //     dataCompany?.is_init_information === false
  //   ) {
  //     replace(AppRoutes.company_creation);
  //   } else if (
  //     dataCompany?.init_information &&
  //     Object.keys(dataCompany?.init_information).length > 0 &&
  //     dataCompany?.is_init_information === false
  //   ) {
  //     replace(
  //       AppRoutes.registration_quetion.detail_step(
  //         dataCompany?.init_information?.next_step
  //       )
  //     );
  //   }
  // }, [dataCompany]);

  return (
    <div style={{ backgroundColor: "#f0f2f5", height: "100vh" }}>
      <div className="container d-flex flex-column align-items-center justify-content-center w-100 h-100">
        <BankOutlined
          size={128}
          style={{ paddingRight: "4px", fontSize: "128px" }}
        />
        <h2 style={{ opacity: 0.5 }} className="mt-3 text-capitalize">
          {t("workspaces_list")}
        </h2>
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-center mt-4 w-100 gap-3">
          {isLoading && (
            <Card
              style={{
                marginRight: "16px",
                marginBottom: "16px",
                minWidth: "280px",
              }}
              loading
            />
          )}
          {!isLoading &&
            listCompaniesData?.data?.map((company, index) => {
              const link = AppCompanyRoutes(company.slug).dashboard;
              return (
                <div
                  key={`company_${company.slug}`}
                  className="col-xl-3 col-lg-4 col-12">
                  <FadeIn delay={400 * index}>
                    <Card
                      title={
                        <div className="mt-3">
                          <div style={{ fontSize: "12px" }}>{t("company")}</div>
                          <h3>{company.name}</h3>
                        </div>
                      }
                      bordered
                      style={{
                        minWidth: "280px",
                        borderRadius: "10px",
                      }}>
                      <Link href={link || "#"}>
                        <Button
                          onClick={() =>
                            localStorage.removeItem("COMPANY_SLUG")
                          }
                          type="primary"
                          style={{ width: "100%", borderRadius: "6px" }}>
                          {t("access")}
                        </Button>
                      </Link>
                    </Card>
                  </FadeIn>
                </div>
              );
            })}
          {!isLoading && listCompaniesData?.data?.length === 0 && (
            <FadeIn>
              <Empty />
            </FadeIn>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationGuarded(DashboardPage);
