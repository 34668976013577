"use client";

import { Spin } from "antd";
import "./index.css";
import { Puff } from "react-loader-spinner";
import FadeIn from "@/components/shared/FadeIn";

interface LoadingPageProps {
  text?: string;
}

const LoadingPage = ({ text }: LoadingPageProps) => {
  return (
    <div className="loading-container">
      <Puff
        visible={true}
        height="64"
        width="64"
        color="var(--bs-primary)"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <FadeIn delay={400} key={text}>
        <h4 style={{ marginTop: "16px" }}>{text}</h4>
      </FadeIn>
    </div>
  );
};

export const LoadingSubPage = () => {
  return (
    <div className="loading-subpage-container">
      <Spin style={{ color: "var(--ant-color-primary)" }} />
    </div>
  );
};

export default LoadingPage;
