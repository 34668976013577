"use client";
import type { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { IProfile, ServerFormError } from "@/services/types";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useAuthentication } from "@/providers/app/AuthenticationProvider";
import { useEffect } from "react";

export const useMyProfileKey = "current-profile";

function _useMyProfile() {
  const { axiosInstance, API_ROUTES } = useGlobalAppState();
  return useQuery<AxiosResponse<IProfile>, ServerFormError>({
    queryKey: [useMyProfileKey],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES.profile.me);
    },
  });
}

function useMyProfile() {
  const { setUser } = useAuthentication();
  const props = _useMyProfile();

  useEffect(() => {
    if (props?.data) {
      setUser(props?.data?.data);
    }
  }, [props, setUser]);
  return props;
}

export default useMyProfile;
